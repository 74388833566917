import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MoxiPreloaderService {
  private readonly document = inject(DOCUMENT);

  removeMoxiPreloader(): void {
    const loaderClassName = '.moxi-loader';
    const loaderHiddenClassName = 'moxi-loader--hidden';
    const loader = this.document.documentElement.querySelector(loaderClassName);

    if (!loader) {
      throw Error('Moxi preloader not found');
    }

    loader.classList.add(loaderHiddenClassName);
  }
}
