import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@shared/providers';
import { navigateToUrl } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  private readonly window = inject(WINDOW);

  detectMobileDevice(): void {
    const userAgent = navigator.userAgent || (this.window as any).opera;
    const mobileRegex = /iPhone|Android.*Mobile|Windows Phone/i;

    if (mobileRegex.test(userAgent)) {
      navigateToUrl('/mobile-notification');
    }
  }
}
